<template>
  <div class="my-lg-5 my-md-7 my-2">
    <div class="row align-items-center">
      <div class="col col-lg-6 d-none d-lg-block text-center">
        <img
          class=""
          src="@/assets/continue-email.svg"
          alt="people with thumbs up signs"
        />
      </div>

      <div class="col col-md-9 col-lg-6 mx-auto">
        <div class="col-12 mb-5 text-center d-none d-lg-block"></div>

        <!--                Step-3-->
        <div class="card d-lg-none shadow-sm py-3 mb-3">
          <div class="col d-flex d-none align-items-center">
            <!--                  Step Number-->
            <div
              class="mr-3 px-3 py-2 shadow-sm align-self-center badge badge-primary"
              style="font-size: 150%; border-radius: 10px"
            >
              3
            </div>
            <!--                  Step Number Text-->
            <div class="text-left">
              <span class="h4">Step 3</span>

              <!--                    Step Details-->
              <div class="text-muted">Confirm Email</div>
            </div>
          </div>
        </div>

        <!--        Content-->
        <div class="card shadow-sm pb-2">
          <div class="card-body">
            <div class="pb-3 mb-4 border-bottom">
              <div class="h3">Confirm your email</div>
            </div>

            <span class="h5 text-muted"
              >We have sent you an email, please complete your registration by
              clicking the link in the email and setting up password.</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContinueEmail",
};
</script>

<style scoped></style>
